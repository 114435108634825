import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const filter = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const specialCharsRegex = /[^A-Za-z0-9-&/ ]/; // match any non-alphanumeric characters

export default function AAutoComplete(props) {
  const {
    labelValue,
    className,
    isNotCreatable,
    isCheckApproved,
    isAdvancedCheck,
    mt,
    value,
    isUpperCaseQuery,
    InputProps,
  } = props;
  const [error, setError] = useState(false);

  const validateInput = (newValue) => {
    const value = newValue.inputValue || newValue[props.name];
    if (specialCharsRegex.test(value)) {
      setError("No Special Characters e.g.: %<>$^?'\"");
      return false;
    }

    if (isAdvancedCheck && !/^[A-Z0-9]+$/.test(value)) {
      setError("Error: only capital case and digits allowed.");
      return false;
    }

    if (
      props.option.some(({ [props.labelValue]: propsValue }) => {
        return propsValue?.toLowerCase() === value?.trimEnd()?.toLowerCase();
      })
    ) {
      setError("Error: value already exists.");
      return false;
    }

    setError(false);
    return true;
  };

  const handleChange = async (event, newValue) => {
    if (typeof newValue === "string") {
      props.set({
        [props.keyValue || props.name]: newValue || "",
      });
    } else if (newValue && newValue.inputValue) {
      if (!validateInput(newValue || "")) {
        return;
      }
      // Create a new value from the user input
      const resp = await props.update(
        newValue.inputValue?.trim().toUpperCase()
      );
      if ((isCheckApproved && resp.isApproved) || !isCheckApproved) {
        props.set({
          [props.keyValue || props.name]: resp,
        });
      }
    } else if (!event.target?.value && !newValue) {
      props.set({
        [props.keyValue || props.name]: event.target?.value || "",
      });
    } else {
      props.set({
        [props.keyValue || props.name]: newValue || "",
      });
    }
  };

  const filterOptions = (options, params) => {
    const { inputValue: value } = params;

    const inputValue = isUpperCaseQuery ? value?.toUpperCase() : value
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === option[labelValue]
    );

    if (inputValue !== "" && !isExisting && !isNotCreatable) {
      filtered.push({
        inputValue,
        [labelValue]: `(+) Add New "${inputValue}"`,
      });
    }

    return filtered;
  };

  const renderOption = (prop, option, { index }) => {
    if (
      props.checkDisabled &&
      !option?.approved &&
      typeof option?.approved == "boolean"
    )
      return (
        <div
          {...prop}
          key={`${option[labelValue]}${index}`}
          className={`option-label ${prop.className}`}
        >
          {option[labelValue]} - (Not Approved)
        </div>
      );
    return (
      <div
        {...prop}
        key={`${option[labelValue]}${index}`}
        className={`option-label ${prop.className}`}
      >
        {option[labelValue]}
      </div>
    );
  };

  return (
    <Stack className={className} key={value} sx={{ m: 1, mt: mt || 1.3 }}>
      <Autocomplete
        className={props.className}
        hidden={props.hidden}
        value={value}
        getOptionDisabled={(option) => {
          return (
            props?.checkDisabled &&
            typeof option?.isApproved == "boolean" &&
            !option?.isApproved
          );
        }}
        onChange={handleChange}
        filterOptions={filterOptions}
        id={props.id}
        options={props.option}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option[labelValue];
        }}
        renderOption={renderOption}
        sx={{
          ...props.sx,
        }}
        selectOnFocus
        clearOnBlur
        clearIcon={props.clearIcon}
        handleHomeEndKeys
        size="small"
        freeSolo
        renderInput={(params) => {
          if (props.clearIcon) InputProps.endAdornment = null;
          return (
            <TextField
              {...params}
              error={error}
              helperText={error}
              InputLabelProps={{ shrink: props.hideLabel }}
              placeholder={props.placeholder}
              label={props.label}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
            />
          );
        }}
      />
    </Stack>
  );
}
