import { accessTypes } from "./accessTypes";

export const roles = {
  owner: [...Object.values(accessTypes.eventManagement)],

  admin: [...Object.values(accessTypes.eventManagement)],

  "event director lead auditor": [],

  "event director lead": [],

  "event director": [],

  "event director assistant": [],

  staffer: [],

  "staffing manager": [],

  associate: [],
};
